import React from 'react';
import {
  Box,
  Stack,
  Button,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  IconButton,
  Circle,
  useDisclosure,
} from '@chakra-ui/react';
import Logo, { MobileLogo } from 'components/common/Logo';
import useViewport from 'utils/Hooks/useViewPort';
import { BiUserCircle } from 'react-icons/bi';
import { AiOutlineShoppingCart, AiOutlineHeart } from 'react-icons/ai';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SidebarDrawer from './SidebarDrawer';
import { useHistory } from 'react-router-dom';
import { customToast } from '../../../components/common/Toast';
import LogoutAlert from './LogoutAlert';

const NavItems = [];

export const NavWebItems = ({ name, path }) => (
  <Button variant="ghost" color="brand.500" as={RouterLink} to={path}>
    {name}
  </Button>
);

export const NavMobileItems = ({ name }) => (
  <Box fontSize="md" color="brand.500">
    {name}
  </Box>
);

function SignInButton() {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const logout = () => {
    localStorage.clear();
    customToast({ title: 'Success', description: 'Logged Out' });
    onClose();
    history.push('/login');
  };

  return (
    <>
      <LogoutAlert onClose={onClose} onOk={logout} isOpen={isOpen} />
      {localStorage.getItem('token') ? (
        <Menu>
          <MenuButton display="flex" flex-direction="row">
            <BiUserCircle size="36px" />
          </MenuButton>
          <MenuList>
            <MenuItem>
              <RouterLink to="/app/my-domains">My Domains</RouterLink>
            </MenuItem>
            <MenuItem onClick={onOpen}>Logout</MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <>
          <Button
            as={RouterLink}
            to="/login"
            background={'brand.500'}
            _hover={{ background: 'brand.500' }}
            _active={{ background: 'brand.500' }}
          >
            Sign in
          </Button>
          <Button
            as={RouterLink}
            to="/signup"
            background={'brand.500'}
            _hover={{ background: 'brand.500' }}
            _active={{ background: 'brand.500' }}
          >
            Sign Up
          </Button>
        </>
      )}
    </>
  );
}

function MobileNav() {
  const cartDetails = useSelector(state => state?.entities?.cartDetails);
  const wishlist = useSelector(state => state?.entities?.wishlist);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      px="2"
      py="2"
      boxShadow="md"
    >
      <Stack direction="row">
        <MobileLogo />
      </Stack>
      <HStack>
        <IconButton
          as={RouterLink}
          to="/wishlist"
          variant="outline"
          // icon={<AiOutlineHeart />}
          icon={
            <>
              <AiOutlineHeart color={'gray.750'} />
              <Circle
                as={'span'}
                color={'white'}
                position={'absolute'}
                top={'6px'}
                right={'4px'}
                bgColor={'red'}
                zIndex={1}
                fontSize="xs"
                size="12px"
              >
                {wishlist?.length}
              </Circle>
            </>
          }
        ></IconButton>
        <IconButton
          as={RouterLink}
          to="/cart"
          icon={
            <>
              <AiOutlineShoppingCart color={'gray.750'} />
              <Circle
                as={'span'}
                color={'white'}
                position={'absolute'}
                top={'6px'}
                right={'4px'}
                bgColor={'red'}
                zIndex={1}
                fontSize="xs"
                size="12px"
              >
                {cartDetails?.length}
              </Circle>
            </>
          }
          variant="outline"
        ></IconButton>
        <SignInButton />
        <SidebarDrawer />
      </HStack>
    </Stack>
  );
}

function DesktopNav() {
  const cartDetails = useSelector(state => state?.entities?.cartDetails);
  const wishlist = useSelector(state => state?.entities?.wishlist);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      px={{ sm: '2', lg: '10' }}
      py="2"
      boxShadow="md"
    >
      <Stack direction="row" alignItems="center">
        <SidebarDrawer />
        <Logo />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        spacing={{ base: '2', lg: '4' }}
      >
        <Button
          as={RouterLink}
          to="/wishlist"
          leftIcon={
            <AiOutlineHeart
              style={{ color: wishlist?.length ? 'red' : '' }}
              fontSize="20px"
            />
          }
          rightIcon={<Badge fontSize="1rem">{wishlist?.length}</Badge>}
          variant="outline"
        ></Button>
        <Button
          as={RouterLink}
          to="/cart"
          leftIcon={<AiOutlineShoppingCart />}
          rightIcon={
            <Badge fontSize="1rem">{cartDetails && cartDetails.length}</Badge>
          }
          variant="outline"
        ></Button>
        <SignInButton />
      </Stack>
    </Stack>
  );
}

export default function Nav() {
  const { width } = useViewport();
  const breakpoint = 1100;
  return width < breakpoint ? <MobileNav /> : <DesktopNav />;
}
