import { customToast } from 'components/common/Toast';
export const getUserRequest = data => {
  return {
    url: `/api/user`,
    body: data,
    transform: responseBody => {
      const { result } = responseBody;
      return { user: result };
    },
    update: {
      user: (prevValue, newValue) => newValue,
    },
  };
};

export const getTotalTransactionValueRequest = data => {
  return {
    url: `/api/transactions/total`,
    body: data,
    transform: responseBody => {
      const { result } = responseBody;
      return { totalTransactionValue: result };
    },
    update: {
      totalTransactionValue: (prevValue, newValue) => newValue,
    },
  };
};

export const updateUserMutation = data => {
  return {
    url: `/api/user`,
    body: data,
    options: {
      method: 'PUT',
    },
    transform: responseBody => {
      const { result } = responseBody;
      return { user: result };
    },
    update: {
      user: (prevValue, newValue) => {
        customToast({
          title: 'Success',
          description: 'Update user successfully',
        });
        return newValue;
      },
    },
  };
};

export const resendVerificationEmailMutation = () => {
  return {
    url: `/api/resend-verification-email`,
    update: {
      resendVerificationEmail: (prevValue, newValue) => {
        customToast({
          title: 'Success',
          description: 'Sent verification email successfully',
        });
        return newValue;
      },
    },
  };
};

export const getUser = state => state.entities.user;
export const getTotalTransactionValue = state =>
  state.entities.totalTransactionValue;
