export const InitiateTransaction = data => {
  return {
    url: `/api/transactions`,
    body: data,
    transform: body => ({
      transactionInfo: body.data,
      transactionMode: body.data.paymentMode,
    }),
    update: {
      transactionInfo: (prev, next) => next,
      transactionMode: (prev, next) => next,
    },
  };
};

export const cancelOrderTransaction = ({ id }) => {
  return {
    url: `/api/orders/cancel-order-transaction/${id}`,
    // options:{
    //   method:"PUT"
    // },
    transform: body => ({
      myOrders: body.data,
    }),
    update: {
      myOrders: (prevValue, newValue) => newValue,
    },
  };
};

export const updatePaypal = (transactionId, data) => {
  return {
    url: `/api/transactions/updatePaypal/${transactionId}`,
    body: data,
    update: {
      transactionInfo: (prev, next) => null,
      transactionMode: (prev, next) => null,
    },
  };
};

export const CreateOrder = data => {
  return {
    url: `/api/orders`,
    body: data,
    transform: body => {
      window.location.href = '/order/' + body.data;
      return {
        orderId: body.data,
      };
    },
    update: {
      orderId: (prev, next) => next,
    },
  };
};

export const GetMyOrders = () => {
  return {
    url: `/api/orders/myorders`,
    transform: body => ({
      myOrders: body.data,
    }),
    update: {
      myOrders: (prev, next) => next,
    },
  };
};

export const getTransactionById = transactionId => {
  return {
    url: `/api/transactions/${transactionId}`,
    transform: body => ({
      getTransactionByIdInfo: body.data,
    }),
    update: {
      getTransactionByIdInfo: (prev, next) => next,
    },
  };
};

export const GetOrder = id => {
  return {
    url: `/api/orders/${id}`,
    transform: body => ({
      order: body.data,
    }),
    update: {
      order: (prev, next) => next,
    },
  };
};

export const getOrderToComplete = id => {
  return {
    url: `/api/orders/getDomainToConfigure/${id}`,
    transform: body => ({
      domainsToConfigure: body.data,
    }),
    update: {
      domainsToConfigure: (prev, next) => next,
    },
  };
};

export const updateDomainResolver = (id, data) => {
  return {
    url: `/api/orders/complete-domain-registration/${id}`,
    body: data,
  };
};

export const getMyDomains = () => {
  return {
    url: `/api/domains`,
    transform: body => ({
      myDomains: body.data,
    }),
    update: {
      myDomains: (prev, next) => next,
    },
  };
};

export const getTransactionStatus = txHash => {
  return {
    url: `/api/transactions/paymentstatussubscribe/${txHash}`,
    transform: body => ({
      txStatus: body.data,
    }),
    update: {
      txStatus: (prev, next) => next,
    },
  };
};

export const getPremiumDomain = () => {
  return {
    url: `/api/premium-domain`,
    transform: body => ({
      premiumDomains: body.data,
    }),
    update: {
      premiumDomains: (prev, next) => next,
    },
  };
};

export const getTLD = () => {
  return {
    url: `/api/tld`,
    transform: body => ({
      tlds: body.result,
    }),
    update: {
      tlds: (prev, next) => next,
    },
  };
};

export const getAvailableCoupons = () => {
  return {
    url: `/api/orders/coupons/getAvailableCoupons`,
    transform: body => ({
      availableCoupons: body.data,
    }),
    update: {
      availableCoupons: (prev, next) => next,
    },
  };
};

export const applyCoupon = (orderId, discountCoupon) => {
  return {
    url: `/api/orders/applyCoupon`,
    body: {
      orderId,
      discountCoupon,
    },
    transform: body => ({
      order: body.data,
    }),
    update: {
      order: (prev, next) => next,
    },
  };
};

export const getCreditsEnum = () => {
  return {
    url: `/api/credits/amounts`,
    transform: body => ({
      creditsOptions: body.result,
    }),
    update: {
      creditsOptions: (prev, next) => next,
    },
  };
};

export const getInfluencers = () => {
  return {
    url: `/api/credits/influencer`,
    transform: body => ({
      influencerOptions: body.result,
    }),
    update: {
      influencerOptions: (prev, next) => next,
    },
  };
};

export const addCredits = body => {
  return {
    url: '/api/credits/',
    options: {
      method: 'POST',
    },
    body: {
      ...body,
    },
    transform: body => ({
      creditsTransactionInfo: body.result,
      creditsPaymentMode: body.result.paymentMode,
    }),
    update: {
      creditsTransactionInfo: (prev, next) => next,
      creditsPaymentMode: (prev, next) => next,
    },
  };
};

export const getUserCredits = () => {
  return {
    url: `/api/credits`,
    transform: body => ({
      myCredits: body.result,
    }),
    update: {
      myCredits: (prev, next) => next,
    },
  };
};
export const getUserCreditHistory = () => {
  return {
    url: `/api/credits/history`,
    transform: body => ({
      myCredits: body.result,
    }),
    update: {
      myCredits: (prev, next) => next,
    },
  };
};

export const getCorrespondingTokenForAmount = ({ amount }) => {
  return {
    url: `/api/credits/tokens`,
    options: {
      method: 'POST',
    },
    body: {
      amount,
    },
    transform: body => ({
      creditsNexbTokens: body.result,
    }),
    update: {
      creditsNexbTokens: (prev, next) => next,
    },
  };
};
