import createRoutes from 'createRoutes';
import * as React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.css';
import withFirebaseAuth from 'react-with-firebase-auth';
import firebase from 'firebase/app';
import 'firebase/auth';
import FullPageLoader from 'components/common/FullPageLoader';
import { ChakraProvider } from '@chakra-ui/react';
import app from './base';
import Layout from './pages/Layout';
import theme from './theme';
import { updateCart } from 'components/Domain/cartUtils';
import { useMutation, useRequest } from 'redux-query-react';
import { updateCartMutation, getCartRequest } from 'entities/cartEntity';
import {
  getWishlistRequest,
  updateWishlistMutation,
} from 'pages/Wishlist/entities';
import { updateWishlist } from 'utils/Wishlist/wishlist';
import { getUserRequest } from 'pages/User/userEntity';
import { getTLD } from 'query-configs/transaction';
import ScrollToTop from './scrollTop';

const firebaseAppAuth = app.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `translateX(${styles.offset}px)`,
  };
}

function App(props) {
  useRequest(getUserRequest());
  const [, updateCartApi] = useMutation(updateCartMutation);
  const [, updateWishlistApi] = useMutation(updateWishlistMutation);
  useRequest(getCartRequest);
  useRequest(getWishlistRequest());
  useRequest(getTLD());

  React.useEffect(() => {
    updateCart(updateCartApi);
    updateWishlist(updateWishlistApi);
  }, []);

  return (
    <div className="App wrapper">
      <ChakraProvider theme={theme}>
        <Router>
          <ScrollToTop />
          <Switch>
            <Layout>
              <React.Suspense fallback={<FullPageLoader />}>
                {createRoutes(props)}
              </React.Suspense>
            </Layout>
          </Switch>
        </Router>
      </ChakraProvider>
    </div>
  );
}

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);
