import { Text } from '@chakra-ui/react';
import * as React from 'react';

const Copyright = props => (
  <Text fontSize="sm" {...props}>
    Copyright &copy; {new Date().getFullYear()} Fordham. All rights reserved.
  </Text>
);

export default Copyright;
