import {
  Button,
  chakra,
  Input,
  Stack,
  Text,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import * as React from 'react';
import Logo from 'components/common/Logo';
import FooterHeading from './FooterHeading';

class SubscribeForm extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // const script = document.createElement('script');
    // script.src = 'https://js.hsforms.net/forms/v2.js';
    // document.body.appendChild(script);
    // script.addEventListener('load', () => {
    //     if(window.hbspt) {
    //         window.hbspt.forms.create({
    //             portalId: '6271347',
    //             formId: '0e745f78-ea3b-44fa-86ed-2f0b1cd34e3f',
    //             target: '#hubspot-subscribe'
    //         })
    //     }
    // });
  }

  render() {
    const props = this.props;
    return (
      <chakra.form {...props} onSubmit={e => e.preventDefault()}>
        <Stack spacing="4">
          <Flex justifyContent="center">
            <Logo />
          </Flex>
          {/*<FooterHeading>Subscribe to our newsletter</FooterHeading>*/}
          {/*<Text>Get notified when we add new components or we have exciting news for you.</Text>*/}

          {/*<Stack*/}
          {/*    spacing="4"*/}
          {/*    direction={{*/}
          {/*        base: 'column',*/}
          {/*        md: 'row',*/}
          {/*    }}*/}
          {/*    id="hubspot-subscribe"*/}
          {/*>*/}

          {/*</Stack>*/}
        </Stack>
      </chakra.form>
    );
  }
}

export default SubscribeForm;
