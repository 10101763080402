import { createStandaloneToast } from '@chakra-ui/react';
import theme from './../../theme';

const toast = createStandaloneToast({ theme: theme });

export const customToast = ({
  title,
  description,
  duration = 5000,
  status = 'success',
  placement = 'bottom-left',
}) => {
  if (typeof description !== 'string') {
    description = 'Something went wrong';
  }
  toast({
    title,
    description,
    status,
    duration,
    isClosable: true,
    position: placement,
  });
};
