import { addToCartEntity, updateCartEntity } from 'entities/cartEntity';
import { getCartItems } from 'utils/Cart/cart';
import { isLoggedIn } from 'utils/Auth';
export const addToCart = (newDomain, addToCartApi) => {
  if (isLoggedIn()) {
    addToCartApi(newDomain);
  } else {
    addToCartEntity(newDomain);
  }
};

export const removeFromCart = (id, cartDetails, removeFromCartApi) => {
  if (isLoggedIn()) {
    removeFromCartApi({ id });
  } else {
    const updateCartItem = cartDetails.filter(item => item._id !== id);
    updateCartEntity(updateCartItem);
  }
};

export const updateCart = updateCartApi => {
  const cartDetails = getCartItems();
  if (isLoggedIn() && cartDetails?.length) {
    updateCartApi(cartDetails);
    localStorage.setItem('cart', []);
  } else {
    updateCartEntity(cartDetails);
  }
};
