import { Image } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from './res/Fordham-University-900028-Tagline-000000---stroke-on-both.svg';
import mobileLogo from './res/favicon-fordham.ico';

export default function Logo() {
  return (
    <Link to="/">
      <Image src={logo} width="210px" height="50px" maxW="none" />
    </Link>
  );
}

export function MobileLogo() {
  return (
    <Link to="/">
      <Image src={mobileLogo} width="36px" height="36px" />
    </Link>
  );
}
