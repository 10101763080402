import { Box, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import * as React from 'react';
import FooterHeading from './FooterHeading';

const LinkGrid = props => (
  <SimpleGrid columns={{ base: 1, md: 3 }} {...props}>
    <Box minW="130px">
      <Text mb="4">Company</Text>
      <Stack>
        <a target="_blank" href="https://www.fordham.edu/">
          About
        </a>
      </Stack>
    </Box>

    <Box minW="130px">
      <Text mb="4">Support</Text>
      <Stack>
        <RouterLink to="/support">Contact Us</RouterLink>
      </Stack>
    </Box>
  </SimpleGrid>
);
export default LinkGrid;
