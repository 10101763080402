import { ButtonGroup, IconButton } from '@chakra-ui/react';
import * as React from 'react';
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiOutlineTwitter,
} from 'react-icons/ai';

const SocialMediaLinks = props => (
  <ButtonGroup variant="ghost" color="gray.600" {...props}>
    <IconButton
      aria-label="twitter"
      variant="ghost"
      target="_blank"
      as="a"
      href="https://twitter.com/FordhamNYC"
      icon={<AiOutlineTwitter size="20px" />}
    />
    <IconButton
      aria-label="linkedin"
      variant="ghost"
      target="_blank"
      as="a"
      href="https://www.linkedin.com/school/7338/?pathWildcard=7338"
      icon={<AiFillLinkedin size="20px" />}
    />
    <IconButton
      aria-label="facebook"
      variant="ghost"
      target="_blank"
      as="a"
      href="https://www.facebook.com/FordhamUniversity"
      icon={<AiFillFacebook size="20px" />}
    />
    <IconButton
      aria-label="instagram"
      variant="ghost"
      target="_blank"
      as="a"
      href=" https://www.instagram.com/fordhamuniversity/"
      icon={<AiFillInstagram size="20px" />}
    />
  </ButtonGroup>
);

export default SocialMediaLinks;
