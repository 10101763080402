import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  IconButton,
  Box,
} from '@chakra-ui/react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { SidebarContent } from './Sidebar';
import Logo from 'components/common/Logo';

export default function SidebarDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const token = localStorage.getItem('token');
  return (
    <>
      <IconButton ref={btnRef} onClick={onOpen} isDisabled={!token}>
        <GiHamburgerMenu size="24px" />
      </IconButton>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody>
            <Box px="4" pb="4">
              <Logo />
              <hr />
            </Box>
            <SidebarContent onClose={onClose} />
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
