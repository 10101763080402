import { updateWishlistEntity } from 'pages/Wishlist/entities';

export const getWishlistItems = () => {
  const wishlist = localStorage.getItem('wishlist');
  try {
    return JSON.parse(wishlist) || [];
  } catch (err) {
    return [];
  }
};

export const setWishlistItems = data => {
  localStorage.setItem('wishlist', JSON.stringify(data));
};

export const addWishlistItemLocalStorage = newItem => {
  const wishlistItems = getWishlistItems();
  const updatedWishlistItems = [...wishlistItems, newItem];
  localStorage.setItem('wishlist', JSON.stringify(updatedWishlistItems));
};

export const updateWishlist = updateWishlistApi => {
  const token = localStorage.getItem('token');
  const wishlist = getWishlistItems();
  if (token && wishlist?.length) {
    updateWishlistApi(wishlist);
    localStorage.setItem('wishlist', []);
  } else {
    updateWishlistEntity(wishlist);
  }
};
