import { updateEntities } from 'redux-query';
import store from 'redux/reduxQueryStore';
import {
  setWishlistItems,
  addWishlistItemLocalStorage,
} from 'utils/Wishlist/wishlist';
import { nanoid } from 'nanoid';

export const updateWishlistEntity = wishlist => {
  setWishlistItems(wishlist);
  store.dispatch(
    updateEntities({
      wishlist: prevValue => wishlist,
    }),
  );
};

export const addToWishlistEntity = newDomain => {
  addWishlistItemLocalStorage(newDomain);
  store.dispatch(
    updateEntities({
      wishlist: prevValue => [...prevValue, { ...newDomain, _id: nanoid() }],
    }),
  );
};

export const addToWishlistMutation = data => {
  return {
    url: `/api/wishlist`,
    body: data,
    transform: responseBody => {
      const { result } = responseBody || {};

      return { wishlist: result?.items || [] };
    },
    update: {
      wishlist: (oldValue, newValue) => {
        return newValue;
      },
    },
  };
};

export const deleteFromWishlistMutation = ({ id }) => {
  return {
    url: `/api/wishlist/${id}`,
    options: {
      method: 'DELETE',
    },
    transform: responseBody => {
      const { result } = responseBody || {};
      const { items = [] } = result || {};
      return { wishlist: items };
    },
    update: {
      wishlist: (prevValue, newValue) => newValue,
    },
  };
};

export const moveToCartMutation = ({ id }) => {
  return {
    url: `/api/wishlist/${id}/move-to-cart`,
    options: {
      method: 'PUT',
    },
    transform: responseBody => {
      const { result } = responseBody || {};
      const { items = [], cartItems = [] } = result || {};
      return { wishlist: items, cartDetails: cartItems };
    },
    update: {
      wishlist: (prevValue, newValue) => newValue,
      cartDetails: (prevValue, newValue) => newValue,
    },
  };
};

export const updateWishlistMutation = data => {
  return {
    url: `/api/wishlist`,
    body: data,
    options: {
      method: 'PUT',
    },

    transform: responseBody => {
      const { result } = responseBody || {};
      const { items = [] } = result || {};
      return { wishlist: items };
    },
    update: {
      wishlist: (prevValue, newValue) => newValue,
    },
  };
};

export const getWishlistRequest = data => {
  return {
    url: `/api/wishlist`,
    body: data,
    transform: responseBody => {
      const { result } = responseBody || {};
      const { items = [] } = result || {};
      return { wishlist: items };
    },
    update: {
      wishlist: (prevValue, newValue) => newValue,
    },
  };
};

export const getWishlist = state => state.entities.wishlist;
