import { Box, HStack, Text, Stack, IconButton, Link } from '@chakra-ui/react';
import * as React from 'react';
import { FiMail } from 'react-icons/fi';
import {
  AiOutlineTwitter,
  AiFillLinkedin,
  AiFillInstagram,
  AiFillFacebook,
} from 'react-icons/ai';

const Banner = () => (
  <Box as="section">
    <Stack
      direction={{ base: 'column', sm: 'row' }}
      color="white"
      fontSize="sm"
      px={6}
      bg="brand.500"
    >
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        align="center"
        spacing="3"
        justify="space-between"
        width="full"
      >
        <Stack direction={{ base: 'column', lg: 'row' }} spacing="4">
          <HStack>
            <IconButton
              color="white"
              variant="ghost"
              icon={<FiMail size="14px" />}
            />
            <Link href="mail:support@fordham.edu">support@fordham.edu</Link>
          </HStack>
        </Stack>
        <Text></Text>
        <HStack>
          <IconButton
            color="white"
            variant="ghost"
            target="_blank"
            as="a"
            href="https://twitter.com/FordhamNYC"
            icon={<AiOutlineTwitter size="18px" />}
          />
          <IconButton
            color="white"
            variant="ghost"
            target="_blank"
            as="a"
            href="https://www.linkedin.com/school/7338/?pathWildcard=7338"
            icon={<AiFillLinkedin size="18px" />}
          />
          <IconButton
            color="white"
            variant="ghost"
            target="_blank"
            as="a"
            href="https://www.facebook.com/FordhamUniversity"
            icon={<AiFillFacebook size="18px" />}
          />
          <IconButton
            color="white"
            variant="ghost"
            target="_blank"
            as="a"
            href=" https://www.instagram.com/fordhamuniversity/"
            icon={<AiFillInstagram size="18px" />}
          />
        </HStack>
      </Stack>
    </Stack>
  </Box>
);

export default Banner;
