import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

const theme = extendTheme(
  {
    styles: {
      global: {
        a: {
          color: 'brand.500',
          textDecoration: 'none',
          _hover: {
            textDecoration: 'underline',
          },
        },
      },
    },
    colors: {
      brand: {
        100: '#033327',
        200: '#900028',
        300: '#900028',
        400: '#900028',
        500: '#900028',
        600: '#900028',
        700: '#900028',
        800: '#900028',
        900: '#900028',
      },
    },
    fonts: {
      body: "'Poppins', sans-serif",
      heading: "'Raleway', sans-serif",
    },
  },
  withDefaultColorScheme({
    colorScheme: 'brand',
    components: ['Button'],
  }),
);

export default theme;
