import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Nav from './components/nav';
import { getUser } from 'pages/User/userEntity';
import Footer from './components/footer';
import Banner from './components/Banner';
import VerifyEmailBanner from './components/VerifyEmailBanner';
import { isLoggedIn } from 'utils/Auth';
import { useLocation } from 'react-router-dom';

const noLayoutPaths = ['transactions/stripe'];

export default function Layout({ children }) {
  const [showEmailVerifyBanner, setShowEmailVerifyBanner] = useState(true);
  const user = useSelector(getUser) || {};
  const location = useLocation();

  const isNoLayoutPath = noLayoutPaths.some(path =>
    location?.pathname?.includes(path),
  );

  if (isNoLayoutPath) return children;

  return (
    <div>
      {user.email && !user.verified && isLoggedIn() && showEmailVerifyBanner ? (
        <VerifyEmailBanner
          closeBanner={() => {
            setShowEmailVerifyBanner(false);
          }}
        />
      ) : (
        <Banner />
      )}
      <Nav />
      {children}
      <Footer />
    </div>
  );
}
